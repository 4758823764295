
import ContactCard2 from "@/components/ContactCard2.vue"; // @ is an alias to /src
import ContactCard4 from "@/components/ContactCard4.vue"; // @ is an alias to /src
import ScrollIndicator from "@/components/ScrollIndicator.vue"
import { defineComponent } from "vue"


export default defineComponent({
  components: {
    ScrollIndicator,
    ContactCard2,
    ContactCard4
  }, 
  name: "HelloWorld",
  
  // The component has a single data property, activeScrollIndex, 
  // which is set to 0 when the component is initialized.
  data() {
    return {
      activeScrollIndex: 0,
      isMobile: false
    }
  },

  // The component also has two lifecycle hooks: mounted and unmounted. 
  // The mounted hook sets up event listeners for the load and scroll events on the window object. 
  // These event listeners will call the videoScroll method when triggered. 
  // The unmounted hook removes these event listeners from the window object.
  mounted() {
    window.addEventListener("load", this.videoScroll);
    window.addEventListener("scroll", this.videoScroll);
    if (navigator.userAgent.match(/Android/i)
         || navigator.userAgent.match(/webOS/i)
         || navigator.userAgent.match(/iPhone/i)
         || navigator.userAgent.match(/iPad/i)
         || navigator.userAgent.match(/iPod/i)
         || navigator.userAgent.match(/BlackBerry/i)
         || navigator.userAgent.match(/Windows Phone/i)) {
            this.isMobile = true ;
         } else {
            this.isMobile = false ;
         }
  },
  unmounted() {
    window.removeEventListener("load", this.videoScroll);
    window.removeEventListener("scroll", this.videoScroll);
  },
 
  // The videoScroll method is a function that checks for the presence of
  // any video elements with the autoplay attribute on the page. 
  // If any such elements are found, it loops through them and determines
  // if they should be played or paused based on their position relative to the viewport. 
  // If the video element is within half its height of the viewport, it is played. 
  // Otherwise, it is paused. The activeScrollIndex property is also updated to 
  // the current index of the video element being processed in the loop.
  methods: {
    videoScroll() {
      if (this.isMobile) {
        return this.imageScroll ()
      } 
      if (document.querySelectorAll("video[autoplay]").length > 0) {
        var windowHeight = window.innerHeight,
        videoEl = document.querySelectorAll("video[autoplay]");
        for (var i = 0; i < videoEl.length; i++) {
          var thisVideoEl: HTMLVideoElement = videoEl[i] as HTMLVideoElement,
            videoHeight = thisVideoEl.clientHeight,
            videoClientRect = thisVideoEl.getBoundingClientRect().top;
          if (
            videoClientRect <= windowHeight - videoHeight * 0.5 &&
            videoClientRect >= 0 - videoHeight * 0.5
          ) {
            this.activeScrollIndex = i
            thisVideoEl.play();
          } else {
            thisVideoEl.pause();
          }
        }
      }        
    },
    imageScroll() {
      if (document.querySelectorAll("img[data-bg]").length > 0) {
        var windowHeight = window.innerHeight,
        imgEl = document.querySelectorAll("img[data-bg]");
        for (var i = 0; i < imgEl.length; i++) {
          var thisImgEl = imgEl[i],
            imgHeight = thisImgEl.clientHeight,
            imgClientRect = thisImgEl.getBoundingClientRect().top;
          if (
            imgClientRect <= windowHeight - imgHeight * 0.5 &&
            imgClientRect >= 0 - imgHeight * 0.5
          ) {
            this.activeScrollIndex = i
          }
        }
      }
    }
  }
});
