
    import { defineComponent, } from "vue"
    
    export default defineComponent({
        name: "ContactCard4",
        props: {
          activeScrollIndex: {
          type: Number,
          required: true
          }
        },
    });
    