
import { defineComponent } from "vue"

    
export default defineComponent({
        name: "ContactCard2",
        props: {
          activeScrollIndex: {
          type: Number,
          required: true
          }
        },
    });
