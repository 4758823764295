
import ContactCard3 from "@/components/ContactCard3.vue"; // @ is an alias to /src
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    ContactCard3
  },
  data() {
    return {
      isMobile: false
    };
  },
  created() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipod|ipad|android|blackberry|webos|iemobile|opera mini/i.test(userAgent);
    this.isMobile = isMobile;
  },
  name: "PromoView"
})
