<template>
    <div>
        <span id="promo-page-background">
            <img src="../assets/seattle.png" />
        </span>
        <a href="https://www.seattleyachtmedia.com/#/">
            <div id="page-logo">
                <img src="../assets/text-logo.png" />
            </div>
        </a>
        <div class="mid">
            <div class="column">
                <h1>Page Not Found</h1>
                <p>The page you're looking for doesn't exist.</p>
                <a href="https://www.seattleyachtmedia.com/#/">
                    SeattleYachtMedia.com
                </a>
            </div>
        </div>

    </div>

</template>
  

<style lang="scss">

.column{
    width: 100%;
    display: flex;
    flex-direction: column;
    // height: 100vh ;
    // align-items: center;
}

.mid{
    width: 100%;

    display: flex;
    // flex-direction: column;
    height: 100vh ;
    align-items: center;
}

#page-logo {
    position: absolute;
    top: 36px;
    @media (orientation: portrait) {
    top: 24px;
    }
    width: 100%;
    height: 12%;
    @media (orientation: portrait) {
        height: 8%;
        // margin-top: 4px;
    }
    img {
        height: 100%;
        width: auto;
    }
}
#promo-page-background {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: -99;
    img {
        z-index: -99;
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        min-width: 100%;
        height: 100%;
        width: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(1.1);
        filter: blur(5px) brightness(80%);
    }
}

</style>
