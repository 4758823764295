<template>
  <nav class="navbar">
  </nav>
  <router-view />
</template>

<script>

import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  // watch: {
  //   $route: {
  //     immediate: true,
  //     handler(to, from) {
  //       document.title = to.meta.title || 'Seattle Yacht Media';
  //       const metaDescription = document.querySelector('meta[name="description"]');
  //       if (metaDescription) {
  //         metaDescription.content = to.meta.description || 'Seattle Yacht Media is the PNWs premier provider of top-quality marine-based media solutions.';
  //       }
  //     },
  //   },
  // },
  // props: {
  //   msg: String,
  // },
  mounted() {},
});

</script>

<style lang="scss">

html {
  scroll-behavior: smooth;
}

body {
  background-color: rgb(236, 235, 244);
  overflow-x: hidden;
}

* {
  padding: 0;
  margin: 0;
}

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;

  @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;700&display=swap');
  font-family: 'Oswald', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  overflow-x: hidden;

}

.navimg {
  width: 64px;
  opacity: 100%;
  display: flex;
  justify-content: flex-end;
  // padding-left: 12px;
  @media (orientation: portrait) {
    // border: 20px lime dashed;
    width: 48px;
    padding: 0px;
    // margin-top: 48px;
  }
}

.navbar {
  // background-color: #00000043;
  text-transform: uppercase;
  overflow: auto;
  height: auto;
  padding: 30px -30px;
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  z-index: 123;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
  justify-content: flex-end;
  align-items: center;
  a {
    transition: 0.25s;
    color: #e3bc5f;
    text-align: center;
    padding: 18px 18px;
    text-decoration: none;
    opacity: 0.5;
    font-size: 18px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    @media (orientation: portrait) {
      // border: 20px lime dashed;
      font-size: 12px;
      padding: 10px 10px;
    }
    &:hover {
      color: #e3bc5f;
      /* transform: scale(1.2, 1.2); */
      font-weight: bold;
      opacity: 0.8;
      text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.8);
    }
    &.router-link-exact-active {
      opacity: 1;
      text-shadow: 3px 3px 3px rgb(0, 0, 0);
    }
  }
}

.main {
  padding: 16px;
  margin-top: 30px;
}

#ContactCard2 {

}

</style>
