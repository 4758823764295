import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import patcharee from '../views/patcharee.vue'
import boatpage2 from '../views/boatpage2.vue'
import PromoView from '../views/PromoView.vue'
import NotFound from '../views/NotFound.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // {
  //   path: '/patcharee',
  //   name: 'patcharee',
  //   component: patcharee
  // },   
  // {
  //   path: '/boatpage2',
  //   name: 'boatpage2',
  //   component: boatpage2
  // },   
  {
    path: '/promo',
    name: 'PromoView',
    component: PromoView
  },
  // { path: '/', component: Home },
  // Define your other routes here...
  { path: '/:pathMatch(.*)*', component: NotFound },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router